<template>
  <treeselect
    :value="value"
    :options="options"
    @input="$emit('input', $event)"
    :default-expand-level="expandLevel"
    v-bind="$attrs"
  />
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    Treeselect
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean
    },
    expandLevel: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style>
h2 {
  font-size: 12px;
}

.vue-treeselect__input-container {
  input {
    min-height: auto !important;
  }
}
</style>
